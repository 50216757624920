import { render, staticRenderFns } from "./Power.vue?vue&type=template&id=449f8bfd&scoped=true&"
import script from "./Power.vue?vue&type=script&lang=ts&"
export * from "./Power.vue?vue&type=script&lang=ts&"
import style0 from "./Power.vue?vue&type=style&index=0&id=449f8bfd&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "449f8bfd",
  null
  
)

export default component.exports