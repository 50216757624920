




























































































































































import { Component, Vue } from "vue-property-decorator";

import AssignCard from "@/components/common/AssignCard.vue";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import AppArea from "@/components/common/AppArea.vue"; // @ is an alias to /src
@Component({
  components: {
    Layout,
    AssignCard,
    AppArea,
  },
})
export default class extends Vue {
  data() {
    return {
      bg: require("@/assets/power/img/bg.png"),
      sone: require("@/assets/power/img/sone.png"),
      stwo: require("@/assets/power/img/stwo.png"),
      sicon: require("@/assets/power/img/sicon.png"),
      sthree: {
        left: require("@/assets/power/img/sthree.png"),
        right: require("@/assets/power/img/sthree.png"),
      },
      sfour: require("@/assets/power/img/sfour.png"),
      p1: "",
      sfive: require("@/assets/storage/img/sfive.png"),
    };
  }
}
